<template>
	<div id="software">
		<section class="is-flex">
			<div
				class="is-flex is-column has-width-one has-bg-primary is-centered-in-middle has-color-light"
			>
				<div class="mx-20 has-text-left">
					<h1>Programvara</h1>
					<p class="my-10">
						Med en licens på vår programvara Analyscenter blir det enkelt för
						revisorer & rådgivare att snabbt och effektivt analysera bolag och
						skapa färdiga presentationer.
					</p>
					<p class="my-10">
						Vår kompletta databas med bokslut för alla Sveriges aktiebolag kan
						enkelt kompletteras med dagsaktuell information genom SIE-import.
					</p>
					<p class="my-10">
						För fleranvändarlicenser erbjuder vi anpassningsbara och
						lättillgängliga molnlösningar med högsta säkerhet.
					</p>
				</div>
			</div>
			<div class="has-width-two testb has-bg-contrast">
				<img src="@/assets/img/dummy_value.png" />
			</div>
		</section>

		<section class="is-flex">
			<div class="has-width-two testb has-bg-contrast">
				<img src="@/assets/img/dummy_presentation.png" />
			</div>

			<div
				class="is-flex is-column has-width-one has-bg-primary is-centered-in-middle has-color-white"
			>
				<div class="mx-20 has-text-left">
					<h2 class="mb-05">Alla Sveriges aktiebolag</h2>

					<p class="mb-20">
						Med vår databas har ni tillgång till uppdaterad information om alla
						Sveriges aktie&shy;bolag.
					</p>
					<h3 class="mb-05">Bolagsinformation</h3>
					<p class="mb-20">
						Adressuppgifter, befattningshavare, branschinformation och mycket
						mer presenteras på ett informativt sätt.
					</p>
					<h3 class="mb-05">Fem senaste bokslut</h3>
					<p class="mb-20">
						I databasen har vi komplett information om de fem senaste boksluten.
					</p>
					<h3 class="mb-05">SIE-import</h3>
					<p class="mb-20">
						För att kunna basera analysen även på icke-publik information har vi
						möjliggjort en enkel SIE-import.
					</p>
				</div>
			</div>
		</section>

		<section class="is-flex">
			<div
				class="is-flex is-column has-width-one has-bg-primary is-centered-in-middle has-color-light"
			>
				<div class="m-20 has-text-left">
					<h2 class="mb-05">Analysmoduler</h2>
					<p class="mb-20">
						Förutom att lista och visualisera bolags- och
						boksluts&shy;information inkluderar Analyscenter även tre
						analysmoduler.
					</p>

					<h3 class="mb-05">Nyckeltalsanalys</h3>
					<p class="mb-20">
						Vår egenutvecklade Bolagskompass är ett pedagogiskt sätt att
						visulisera resultat- och balans&shy;räkningen genom en rad
						nyckeltal.
					</p>

					<h3 class="mb-05">Kassaflödesanalys</h3>
					<p class="mb-20">
						Vi har byggt en automatisk modell som delar upp kassa&shy;flöden på
						operativa kassa&shy;flöden, bindning i rörelse&shy;kapital,
						investeringar och finansiering.
					</p>

					<h3 class="mb-05">Kassaflödesvärdering</h3>
					<p>
						Programmet räknar ut alla relevanta variabler medans råd&shy;givaren
						har möjlighet att mycket enkelt lägga sista handen på
						slut&shy;resultatet och därmed justera bolagsvärdet.
					</p>
				</div>
			</div>
			<div class="has-width-two testb">
				<img src="@/assets/img/dummy_value.png" />
			</div>
		</section>

		<section class="is-flex">
			<div class="has-width-two testb has-bg-contrast">
				<img src="@/assets/img/dummy_presentation.png" />
			</div>

			<div
				class="is-flex is-column has-width-one has-bg-primary is-centered-in-middle has-color-white"
			>
				<div class="m-20 has-text-left">
					<h2 class="mb-05">
						Presentationsläge
					</h2>
					<p class="mb-20">
						Med ett par klick skapas färdiga presentationer i professionellt
						utförande som kan visas i det inbyggda presentations&shy;läget.
					</p>
					<h3 class="mb-05">Kundanpassat</h3>
					<p class="mb-20">
						Både presentationer och själva programvaran anpassas till form &
						färg för att följa ert varumärke.
					</p>
					<h3 class="mb-05">PDF-dokument</h3>
					<p class="mb-20">
						Vill man komplettera presentationerna med konsultunderlag eller
						kanske en flersidig kundrapport i PDF-format är det lika enkelt.
					</p>
					<h3 class="mb-05">Egna mallar</h3>
					<p class="mb-20">
						Det finns självklart möjlighet att ta fram egna mallar anpassade
						just efter er verksamhet.
					</p>
				</div>
			</div>
		</section>

		<section class="is-flex">
			<div
				class="is-flex is-column has-width-one has-bg-primary is-centered-in-middle has-color-light"
			>
				<div class="m-20 has-text-left">
					<h2 class="mb-05">
						Säkert & skalbart
					</h2>
					<p class="mb-20">
						Analyscenter är utvecklad för krävande kunder med högsta
						säkerhetstänk.
					</p>
					<h3 class="mb-05">Unik instans</h3>
					<p class="mb-20">
						För alla kunder skapas en unik instans som ger en så säker och
						anpassnings&shy;bar tjänst som möjligt.
					</p>
					<h3 class="mb-05">Säker autentisering</h3>
					<p class="mb-20">
						Analyscenter omges av höga krav på säkerhet och vi erbjuder olika
						autentiserings&shy;lösningar för er säkerhet.
					</p>
					<h3 class="mb-05">Skalbar</h3>
					<p class="mb-20">
						Vår prismodell för en fleranvändarlicens skapar möjlighet att börja
						i mindre skala för att därefter stegvis öka användandet.
					</p>
				</div>
			</div>
			<div class="has-width-two testb">
				<img src="@/assets/img/dummy_value.png" />
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: "Software",
	};
</script>

<style lang="scss" scoped>
	#software {
		min-height: 100vh;
		width: 80vw;
		position: absolute;
		left: 20vw;
		background-color: $light;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	section {
		min-height: 100vh;
		scroll-snap-align: start;
	}
</style>
