<template>
	<div>
		<div class="is-flex has-bg-lightgrey">
			<article class="has-text-left">
				<h1 class="my-10 has-text-left">Vart tar pengarna vägen?</h1>
				<p class="ingress">
					Gör bolaget bra resultat men känns det ändå som att pengarna
					försvinner i ett svart hål? Eller vill ni göra en större investering
					och behöver frigöra kapital? Då kan det vara en god idé att börja med
					att göra en
					<span class="has-bg-contrast px-025"
						>kassa&shy;flödes&shy;analys</span
					>
					för att finna svaret på vart pengarna tar vägen.
				</p>

				<p>
					Genom att titta i balans&shy;räkningen och jämföra årets utgående
					balans för posten kassa & bank med motsvarande post föregående år fås
					de totala kassa&shy;flödena för året fram.
				</p>
				<div
					class="my-10 p-10 has-border has-bordercolor-lightgrey has-bg-white"
				>
					<div class="mb-05 has-fontweight-600">Årets kassaflöde =</div>
					[ UB Kassa & Bank ] - [ IB Kassa & Bank ]
				</div>

				<p>
					De totala kassaflödena säger dock inte så mycket då det inte framgår
					om förändringen beror på att bolaget har tjänat pengar, lånat upp
					kapital från sina kredit&shy;givare eller kanske sålt ett dotterbolag.
				</p>

				<h2>Vad är en kassaflödesanalys?</h2>
				<p>
					Med en kassa&shy;flödes&shy;analys delas de totala kassa&shy;flöden,
					d.v.s. förändringen i likvida medel under året, upp i olika kategorier
					för att det skall bli lättare att kunna följa hur pengarna strömmar
					inom bolaget. Detta innebär bättre möjligheter till att såväl optimera
					likviditets&shy;hanteringen som att följa upp de lång&shy;siktiga
					strategiska åtgärderna.
				</p>

				<img src="..//assets/img/infographic_cashflow.png" />
				<div class="caption">Figur 1. Schematisk bild över kassaflöden</div>

				<p>
					Bolag som kategoriseras som stora bolag måste inkludera en
					kassa&shy;flödes&shy;analys i sin års&shy;redovisning, men i stort
					sett alla bolag mår bra av en årlig genomgång av hur sina kassaflöden
					strömmar i bolaget för att därmed försäkra sig om att utvecklingen går
					åt rätt håll.
				</p>

				<h2>Hur används en kassaflödesanalys?</h2>
				<p>
					Kassaflödesanalysen är en underskattad källa till kunskap om hur ett
					företag utvecklas och kan användas såväl operativt för att öka
					med&shy;vetenheten som strategiskt för att förbättra bolagets
					intjänings&shy;förmåga.
				</p>
				<p>
					De flesta företags&shy;värderingar baseras på en
					kassaflödes&shy;värdering så att förstå mekanismerna kring hur
					kassaflöden fördelas samt hur de kan påverkas kan indirekt även höja
					värdet på bolaget.
				</p>

				<h2>Hur görs en kassaflödesanalys?</h2>
				<p>
					För att skapa en kassaflödes&shy;analys kan antingen den direkta eller
					den indirekta metoden användas.
				</p>
				<p>
					Den direkta metoden innebär att man går igenom bok&shy;föringen
					verifikat för verifikat och tittar på hur det påverkar
					kassa&shy;flödena. Denna metod är extremt tids&shy;krävande och
					används därför ytterst sällan.
				</p>

				<h3>Eliminera poster av bokföringsteknisk art</h3>
				<p>
					Den indirekta metoden utgår från siffrorna i bolagets resultat- och
					balansräkning och värdena i dessa skyfflas fram och tillbaks för att
					eliminera poster som endast är av bokföringsteknisk art såsom av- och
					nedskrivningar.
				</p>

				<h3>Kategorisering</h3>
				<p>
					I en kassa&shy;flödes&shy;analys delas de totala kassaflöden upp i
					olika kategorier för att därmed lättare kunna följa hur pengarna rör
					sig inom bolaget.
				</p>

				<h4>Operativa kassaflöden</h4>
				<p>
					De operativa kassa&shy;flödena är de kassa&shy;flöden som genereras
					från den ordinarie verk&shy;samheten och kan därmed sägas utgöra det
					likvida över&shy;skottet som bolaget (förhoppningsvis) gör från att
					köpa och sälja sina produkter eller tjänster.
				</p>
				<h4>Förändring av rörelsekapital</h4>
				<p>
					Det kapital som behövs för att driva en verksamhet kallas för
					rörelse&shy;kapital och utgörs exempelvis av varulager,
					kund&shy;fordringar och leverantörs&shy;skulder. Ju större ett bolag
					blir ju mer kapital binds vanligen som rörelsekapital.
				</p>
				<p>
					När man lägger ihop de operativa kassa&shy;flödena och förändringen av
					rörelse&shy;kapital får man fram det som vanligtvis kallas för
					kassaflöden från den löpande verksamheten.
				</p>
				<h4>Investeringar</h4>
				<p>
					Många, men inte alla, bolag gör regelbundet investeringar i sina
					anläggnings&shy;tillgångar och dessa särskiljs vanligtvis under denna
					kategori. En ny&shy;investering medför ett negativt kassa&shy;flöde
					medans eventuell avkastning från en tidigare gjord investering (t.ex.
					att man säljer av en maskin) resulterar i ett positivt kassaflöde.
				</p>
				<h4>Finansiering</h4>
				<p>
					I finansieringen finns det kapital&shy;strömmar till kredit&shy;givare
					och bolagets ägare. Om bolaget exemelvis tar upp ett banklån innebär
					detta ett positivt kassa&shy;flöde medans en lämnad utdelning till
					ägarna resulterar i ett negativt kassaflöde.
				</p>

				<h3>Avstämning</h3>
				<p>
					När alla elimineringar av poster som inte påverkar kassaflödet och
					kategoriseringen är gjord är det dags att stämma av så att de totala
					kassaflöden blir samma som summan av de operativa kassaflödena,
					förändringen av rörelsekapital, investeringar och finansiering.
				</p>

				<h2>Kan man göra en kassaflödesanalys själv?</h2>
				<p>
					Det finns många olika mallar att tillgå på nätet som man kan använda
					som stöd om man vill göra sin egen kassaflödes&shy;analys. Man skall
					dock vara medveten om att det även för en erfaren ekonom är ganska
					krångligt att göra en kassaflödes&shy;analys och det gäller verkligen
					att hålla tungan rätt i mun så att plus- och minustecknen hamnar på
					rätt ställe.
				</p>
				<p>
					En väldigt bra sak om man försöker göra en egen kassaflödesanalys är
					dock att det, som tidigare nämnts, är väldigt enkelt att stämma av de
					totala kassaflödena och att man därmed alltid har tillgång till facit.
				</p>
				<p class="special">
					Om du tycker att det verkar intressant med en kassaflödesanalys men
					känner att du inte har kunskap eller tid att göra den själv så
					erbjuder vi prisvärda lösningar som du kan
					<router-link to="analyser">
						läsa mer om här.
					</router-link>
				</p>

				<h2>Bra länkar om kassaflödesanalys:</h2>

				<div class="mb-10">
					SRF Konsulterna:
					<a
						href="https://www.srfredovisning.se/foretagsanalys-med-redovisningsinformation/4-analys-med-kassafloden/"
						target="_blank"
						>Genomgång med exempel</a
					>
				</div>
				<div class="mb-10">
					Bokföringsnämnden:
					<a
						href="https://www4.skatteverket.se/download/18.7433fa3716e1e22be7014a08/1576756771943/BFNARVL%202016%2010%20konsoliderad%202019%2012%2006.pdf#page=282"
						target="_blank"
						>Frivilligt upprättad kassaflödesanalys</a
					>
				</div>
				<div class="mb-10">
					Ekonomiportalen:
					<a href="http://www.ekonomiportalen.se/Kassaflode.pdf" target="_blank"
						>Kassaflödesanalys genom indirekt metod</a
					>
				</div>
				<div>
					Skatteverket:
					<a
						href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.5/3255.html#"
						target="_blank"
						>Rättslig vägledning</a
					>
				</div>
			</article>
		</div>
		<div class="has-bg-lightgrey">
			<div class="has-bg-grey has-color-white p-20 testare">
				Test
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ReportCashflow",
	};
</script>

<style lang="scss" scoped>
	a {
		text-decoration: underline $secondary;
		color: $black;
		transition: 0.5s;
		font-weight: 600;
		&:hover {
			color: $secondary;
		}
	}

	.special {
		border: 1px solid $lightgrey;
		border-left: 0.3rem solid $secondary;
		padding: 1rem;
		background: $white;
	}

	.active {
		font-weight: 700;
		color: $secondary;
	}

	body {
		background-color: $contrast;
	}

	img {
		width: 100%;
		border: 1px solid $lightgrey;
		padding: 0.5rem;
		background-color: $white;
		margin-top: 1rem;
		transition: 0.5s;

		&:hover {
			transform: scale(1.25);
			box-shadow: 0 0 50vh 0 $black;
			border-color: $grey;
		}
	}

	.ingress {
		font-size: 1.1rem;
		font-weight: 600;
	}

	.toc {
		position: sticky;
		top: 17.5rem;
		font-size: 0.875rem;
		padding: 1rem 0;

		& div {
			margin: 0.5rem 0;
		}
	}

	.testar {
		background-color: $contrast;
		transform: translateX(calc(100% + 2rem));
		position: sticky;
		top: 100px;
		right: 0px;
	}

	.testare {
		max-width: 960px;
		margin: 0 auto;
	}
</style>
