<template>
	<div id="reports">
		<section class="is-flex is-centered-in-middle">
			<div class="is-column has-width-two p-20 has-color-light">
				<h1 class="has-text-left">Företagsanalyser</h1>

				<div id="intro" class="is-flex">
					<div>
						<p class="my-20 has-text-left">
							Genom att låta vår egenutvecklade programvara göra alla
							beräkningar kan vi erbjuda företagare standardiserade analyser
							till ett attraktivt pris.
						</p>
						<p class="my-20 has-text-left">
							Alla analyser granskas och justeras manuellt av en erfaren
							analytiker och kan levereras i en rad olika format.
						</p>
						<p class="my-20 has-text-left">
							Analyserna baseras antingen på de senaste officiella bokslutet
							eller i kombination med en bifogad SIE-fil från
							bokförings&shy;programmet.
						</p>
					</div>
				</div>
			</div>

			<div
				class="is-flex is-column has-width-three p-20 has-bg-lightgrey is-full-height is-centered-in-middle"
			>
				<div class="is-flex gap-20 mb-20">
					<ReportItem
						report="Företagsvärdering"
						title="Hur mycket är företaget värt?"
						desc="Med en företagsvärdering får du en uppfattning om värdet på ditt bolag och en inblick i hur en erfaren analytiker bedömer verksamheten."
						link="/about"
						image="dummy_value2.png"
					/>
					<ReportItem
						report="Kassaflödesanalys"
						title="Vart tar pengarna vägen?"
						desc="Med en kassaflödesanalys ser du vilka delar av verksamheten som generera pengar och vart de tar vägen."
						link="/analyser/kassaflode"
						image="dummy_cashflow.png"
					/>
				</div>

				<div class="is-flex gap-20">
					<ReportItem
						report="Nyckeltalsanalys"
						title="Hur står sig mitt bolag?"
						desc="Med en nyckeltalsanalys får du en genomgång om hur ditt bolag står sig jämfört med konkurrenter och branschen."
						link="/about"
						image="test1.png"
					/>
					<ReportItem
						report="Bolagsanalys"
						title="Vad kan förbättras?"
						desc="I ett webbmöte på ca. 90 minuter går ni tillsammans med en erfaren analytiker igenom bolaget."
						link="/about"
						image="bk_valuation.jpg"
					/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import ReportItem from "@/components/ReportItem.vue";

	export default {
		name: "Reports",
		components: {
			ReportItem,
		},
	};
</script>

<style lang="scss" scoped>
	#reports {
		width: 80vw;
		position: absolute;
		left: 20vw;
	}

	.is-full-height {
		min-height: 100vh;
	}

	#splash {
		background-color: $light;
		width: 80vw;
		position: absolute;
	}
</style>
