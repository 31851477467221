<template>
	<router-link :to="link" class="link has-width-one">
		<div class="container">
			<div class="container-wrapper">
				<div class="container-image">
					<img :src="require(`@/assets/img/${image}`)" />
				</div>
				<div class="container-header p-10">
					<div class="report">{{ report }}</div>
					<h2 class="has-text-left">{{ title }}</h2>
				</div>
				<div class="container-desc p-10">
					<p class="has-text-left">{{ desc }}</p>
					<p class="has-text-right has-color-secondary">
						Läs mer
					</p>
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		name: "ReportItem",
		props: ["report", "title", "desc", "link", "image"],
	};
</script>

<style lang="scss" scoped>
	.container {
		background: pink;
		border: 1px solid $grey;
		height: 35vh;
		aspect-ratio: 1 / 1;
		overflow: hidden;

		&-wrapper {
			height: 100%;
			transition: 0.5s;
			transform: translateY(0%);
		}

		&-image {
			height: 70%;
		}

		&-header {
			height: 30%;
			background: $dark;
			columns: $light;
			transition: 0.5s;

			.report {
				text-align: left;
				font-size: 0.75rem;
				text-transform: uppercase;
				color: $contrast;
			}

			h2 {
				padding-top: 0.5rem;
				color: $light;
				font-size: 1rem;
			}
		}

		&-desc {
			height: 70%;
			background: $light;
			color: $black;
		}
	}

	.container:hover .container-wrapper {
		transform: translateY(-70%);

		.container-header {
			background: $contrast;

			.report {
				color: $darkgrey;
			}

			h2 {
				color: $black;
			}
		}
	}

	img {
		width: 100%;
		object-fit: cover;
		aspect-ratio: 10 / 7;
	}

	.link {
		text-decoration: none;
	}
</style>
