<template>
	<div id="about">
		<h1>This is an about page</h1>
	</div>
</template>

<script>
	export default {
		name: "About",
	};
</script>

<style lang="scss" scoped>
	#about {
		width: 80vw;
		position: absolute;
		left: 20vw;
		min-height: 100vh;
	}
</style>
