<template>
	<div id="knowledge">
		<section class="is-flex">
			<div class="is-column has-width-one p-20 has-color-light has-bg-grey">
				<h1 class="has-text-left">Företagsanalyser</h1>

				<div id="intro" class="is-flex">
					<div>
						<p class="my-20 has-text-left">
							Genom att låta vår egenutvecklade programvara göra alla
							beräkningar kan vi erbjuda företagare standardiserade analyser
							till ett attraktivt pris.
						</p>
						<p class="my-20 has-text-left">
							Alla analyser granskas och justeras manuellt av en erfaren
							analytiker innan de leveras. Du väljer själv om du vill ha en
							PDF-rapport, inspelad presentation eller kanske som infografik.
						</p>
						<p class="my-20 has-text-left">
							Analyserna baseras antingen på de senaste officiella bokslutet
							eller i kombination med en bifogad SIE-fil från
							bokförings&shy;programmet.
						</p>
					</div>
				</div>
			</div>

			<div class="is-column has-width-three p-20 has-bg-light">B</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: "Knowledge",
	};
</script>

<style lang="scss" scoped>
	#knowledge {
		width: 80vw;
		position: absolute;
		left: 20vw;
		min-height: 100vh;
	}

	.test {
		min-height: 100vh;
		width: 20vw;
	}

	.test1 {
		width: 60vw;
	}
</style>
