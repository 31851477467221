import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Knowledge from "../views/Knowledge.vue";
import Software from "../views/Software.vue";
import About from "../views/About.vue";
import Reports from "../views/Reports.vue";
import ReportCashflow from "../views/ReportCashflow.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/program",
		name: "Software",
		component: Software,
	},
	{
		path: "/om-oss",
		name: "About",
		component: About,
	},
	{
		path: "/kunskap",
		name: "Knowledge",
		component: Knowledge,
	},
	{
		path: "/analyser",
		name: "Reports",
		component: Reports,
	},
	{
		path: "/analyser/kassaflode",
		name: "ReportCashflow",
		component: ReportCashflow,
	},
	{
		path: "/kassaflode",
		name: "ReportCashflow",
		component: ReportCashflow,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

export default router;
